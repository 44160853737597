<template>
  <section ref="stockEl" id="in_stock">
    <div
      class="block-choice-container px-[5%] md:px-[9%]"
      :class="[device_platform]"
    >
      <div class="py-5 text-[28px] font-semibold uppercase md:pb-[40px] md:pt-[90px] md:text-[36px] xl:hidden">
        в наличии
      </div>
      <div class="bg-container">
        <div class="content-container">
          <div class="flex flex-row items-center justify-between lg:pb-[46px] xl:flex-wrap xl:gap-[20px] xl:pt-[90px]">
            <h2 class="hidden font-semibold uppercase xl:flex xl:w-full xl:text-[50px] xl:leading-[40px] 2xl:w-auto">
              в наличии
            </h2>
            <selector-bar
              :values="selectors"
              @selectModel="selectModel"
              :cur_value="cur_selector"
              :b_cars_car="b_cars_car"
            >
            </selector-bar>
          </div>
          <div
            class="blue-bg"
            v-if="
              !['all', 'special'].some((el) => el === cur_selector) ||
              device_platform !== 'mobile'
            "
          >
          </div>
          <div class="search-container">
            <div class="search-item" v-for="item in cars_to_show" :key="item">
              <div
                class="image"
                :class="[
                  orientation(item),
                  {
                    image_special:
                      item.complectation_name.includes('Яндекс.Авто'),
                  },
                ]"
              >
                <div class="five_years_logo">
                  <img
                    :src="require('../images/icons/five_years.png')"
                    alt="Логотип 5 лет гарантии"
                  >
                </div>
                <swiper-container
                  :slides-per-view="1"
                  :space-between="50"
                  navigation="true"
                >
                  <swiper-slide v-for="image in item.images" :key="image">
                    <Image-Item
                      :source="`/back/storage/parser/images/${item.vin}/${image}`"
                      :alt-name="`Фотография модели ${item.model}`"
                      @click="
                        modalStore.showModal({
                          component: 'ModalCallback',
                          title: 'ПОЛУЧИТЬ ВЫГОДУ',
                          button: 'ПОЛУЧИТЬ'
                        })
                      "
                    />
                  </swiper-slide>
                </swiper-container>
              </div>
              <table class="info">
                <tr>
                  <td class="title">Комплектация</td>
                  <td class="value">{{ item.complectation_name }}</td>
                </tr>
                <tr>
                  <td class="title">Технические характеристики</td>
                  <td class="value">{{ item.modification }}</td>
                </tr>
                <tr>
                  <td class="title">Год</td>
                  <td class="value">{{ item.year }}</td>
                </tr>
                <tr>
                  <td class="title">Номер</td>
                  <td class="value">{{ item.vin }}</td>
                </tr>
                <tr>
                  <td class="title">Цвет</td>
                  <td class="value">{{ item.color }}</td>
                </tr>
              </table>
              <p class="price-block">
                от&nbsp;<span class="value price price-block__price">
                  {{ +item.price || finance }}
                </span>&nbsp;₽
              </p>
              <buttons--button-modal-caller
                class-names="btn-blue"
                modal-title="ПОЛУЧИТЬ ВЫГОДУ"
                modal-button="ПОЛУЧИТЬ"
              >
                ПОЛУЧИТЬ ВЫГОДУ
              </buttons--button-modal-caller>
            </div>
            <h3
              class="font-normal mb-[100px] pb-[30px] text-[14px] uppercase md:pb-[40px] md:text-[23px]"
              v-if="cars_to_show.length == 0"
            >
              Автомобили по данному запросу не найдены
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="my-8 flex items-center justify-center xl:mb-7 xl:mt-24">
      <div
        class="cursor-pointer gap-5 rounded-md bg-[#eff3fa] py-4 px-2 text-center text-xl text-primary-blue before:content-['+'] xl:w-96"
        v-show="show_more_btn"
        @click="incCarsNum"
      >
        Показать больше
      </div>
    </div>
  </section>
</template>

<script>
import SelectorBar from './selector-bar';
import ImageItem from './common/Image-Item.vue';
import Mixin from '@/common/mixin';
import Finance from '../common/finance';
import CARS_DATA from '../common/data';
import axios from 'axios';
import { useModalStore } from '@/stores/modalStore';
import { useStockStore } from '@/stores/stockStore';

const COMMON_CARS_SELECTORS = [
  { name: 'Coolray' },
  // { name: 'Tugella' },
  { name: 'Atlas Pro' },
  { name: 'Monjaro' },
  { name: 'Emgrand' },
  { name: 'Atlas' },
  // { name: 'X50' },
  { name: 'Okavango' },
];
const ALL_CARS_SELECTOR = {
  name: 'all',
  text: 'Все модели',
};

export default {
  name: 'block-choice',
  components: {
    SelectorBar,
    ImageItem,
  },
  directives: {},
  mixins: [Mixin, Finance],
  props: ['b_cars_car', 'id'],
  emits: ['getAgreement', 'scrollTo', 'callBack', 'getCall', 'bCarsClicked'],
  setup() {
    const modalStore = useModalStore();
    const stockStore = useStockStore();

    return {
      modalStore,
      stockStore,
    };
  },
  data() {
    return {
      cars: [],
      cars_num: 3,
      cur_selector: 'Coolray',
      cur_comp: '',
      cur_engine: '',
      dev_mode: false,
      href: '',
      search: '',
    };
  },
  created() {
    if (this.dev_mode) {
      this.cars = CARS_DATA.models;
    } else {
      const req_url = location.host.includes('localhost')
        ? '/cars.json'
        : '/back/public/cars';

      axios.get(req_url, {}).then((res) => {
        let cars = res.data.models;
        this.cars = cars || [];
        ALL_CARS_SELECTOR.count = cars.length
      });
    }
  },
  computed: {
    format_number(num) {
      return new Intl.NumberFormat('ru-RU').format(num);
    },
    selectors() {
      let selectorCars = COMMON_CARS_SELECTORS.map((car) => ({
        'name': car.name, 
        'count': this.cars.filter(x => x.model === car.name).length
      }));

      this.stockStore.getStock(selectorCars);
      return [ALL_CARS_SELECTOR].concat(selectorCars.filter(x => x.count != 0));
    },
    prefiltered_cars() {
      if (this.cur_selector === 'all') {
        return this.cars;
      }
      return this.cars.filter((car) => car.model === this.cur_selector);
    },
    filtered_cars() {
      let ret_cars = this.prefiltered_cars;

      if (this.cur_comp.length > 0) {
        ret_cars = ret_cars.filter((car) => car.package === this.cur_comp);
      }
      if (typeof this.cur_engine === 'number' || this.cur_engine.length > 0) {
        ret_cars = ret_cars.filter((car) => car.volume === this.cur_engine);
      }

      ret_cars.sort((a, b) => a.price - b.price);

      return ret_cars;
    },
    cars_to_show() {
      return this.filtered_cars.slice(0, this.cars_num);
    },
    show_more_btn() {
      return this.filtered_cars.length > this.cars_num;
    },
  },
  methods: {
    selectModel(name) {
      this.cars_num = 3;
      this.cur_comp = '';
      this.cur_engine = '';
      this.cur_selector = name;
    },
    setComp(comp) {
      this.cars_num = 4;
      this.cur_comp = comp;
    },
    setEngine(engine) {
      this.cars_num = 4;
      this.cur_engine = engine;
    },
    getName(car) {
      return car.modification.split(' ')[0];
    },
    getInfo(item) {
      let patrol = item.patrol ? item.patrol : '';
      return (
        this.getName(item).toUpperCase() +
        ' ' +
        item.package +
        ' ' +
        item.volume +
        'л. ' +
        patrol +
        ', ' +
        item.hp +
        ' л.с. ' +
        item.transm
      );
    },
    incCarsNum() {
      this.cars_num += 3;
    },
    orientation(item) {
      if (!item.img_orientation) return;
      if (item.img_orientation == 6) return 'left-to-right';
      if (item.img_orientation == 8) return 'right-to-left';
    },
    currentUrl() {
      const searchParams = new URLSearchParams(this.search);

      let symbol = "?";

      if (searchParams.size) {
        return this.href;
      } else if (searchParams.has('block')) {
        symbol = "&";
      }

      return symbol + "block=in_stock";
    }
  },
  mounted() {
    this.href = window.location.href;
    this.search = window.location.search;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.0, 0.75],
    };

    let callback = function(entries) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          window.history.replaceState(null , '', this.currentUrl());
        } else {
          window.history.replaceState(null , '', this.href);
        }
      }
    }.bind(this);

    let observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.stockEl);
  }
};
</script>

<style scoped lang="scss">
@import '../styles/constants.scss';

.desktop {
  .search-item {
    margin-right: calc(8% / 3);

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.mobile {
  .content-container {
    width: calc(100% - 10px);
    padding: 0;
  }

  .header-text {
    font-size: 35px;
  }

  .search-container {
    flex-direction: column;
  }

  .search-item {
    width: 100%;
    // max-width: calc(100% - 40px);
    // margin: 10px auto;
  }

  .offer-btn {
    margin: 10px 0;
    width: 275px;
  }

  .mobile-title {
    position: absolute;
    left: 5px;
    top: 20px;
    font-size: 17px;
    font-weight: 300;
  }

  &.block-choice-container {
    margin-top: 0;
  }

  .value {
    font-size: 13px;
  }

  .price-block__price {
    font-size: 24px;
  }
}

.tablet {
  .search-container {
    justify-content: space-around;
  }

  .search-item {
    width: 50%;
    margin: 2px 10px 40px;
    max-width: 380px;
  }
}

.block-choice-container {
  width: 100%;
  // margin-top: 30px;
  padding-top: 30px;
  // margin-bottom: 100px;
  position: relative;
}

.bottom-layer {
  width: 100%;
  max-width: 1920px;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  height: 670px;
  position: absolute;
  background: url('../images/blue2.png');
  background-size: cover;
}

.bg-container {
  width: 100%;
  // background: #356fb1;
}

.content-container {
  // max-width: 1500px;
  width: 100%;
  // margin: 40px auto 0;
  padding-bottom: 1px;
  position: relative;
}

.dropdown-section {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.dropdown-box {
  margin-left: 20px;
}

.table-header {
  width: 100%;
  border-top: 10px solid $blue;
  border-bottom: 20px solid #efefef;
}

.blue-bg {
  background: #1f6fb6;
}

.search-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.search-item {
  width: 30%;
  margin: 2px 0px 40px;
  background: #eff3fa;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 0 3px #ccc;
  position: relative;
  @apply 2xl:px-[35px] 2xl:py-[30px];
}

.btn-blue {
  // margin: 15px auto 25px;
  @apply w-full;
}

.image {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;

  img {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    image-orientation: none;
  }

  &_special::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 54px;
    background-image: url('../images/utils/percentage.png');
    background-repeat: no-repeat;
  }
}

.offer {
  cursor: pointer;
  color: #4ba82e;
  margin-top: 10px;
}

.info {
  padding: 10px;
  font-size: 15px;
  @media (min-width: 768px) {
      width: 100%;
    }
}

.title {
  font-weight: 300;
}

.value {
  font-weight: 500;
  text-align: end;
}

.title,
.value {
  width: 50%;
  padding: 5px 5px;
}

.price {
  font-weight: 500;
  text-decoration: line-through;
}

.offer-btn {
  width: 295px;
  height: 50px;
  text-align: center;
  background: #4ba82e;
  color: white;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

  &:hover:not(.disabled) {
    background: var(--button_common_hover);
  }
}

.left-to-right {
  overflow: hidden;

  img {
    transform: rotate(90deg);
  }
}

.right-to-left {
  overflow: hidden;

  img {
    transform: rotate(-90deg);
  }
}

.header-text {
  position: relative;
  margin-bottom: 40px;

  &__bg {
    position: absolute;
    bottom: -130px;
    left: 0;

    @media (max-width: 992px) {
      bottom: -155px;
    }
  }
}

.price-block {
  font-size: 14px;
  padding: 10px 5px;
  @apply text-[#002E5D];

  &__price {
    font-size: 24px;
    text-decoration: line-through;
  }
}

.benefit_list_container {
  padding: 10px;
  font-size: 15px;
}
.benefit_list_item:not(:last-child) {
  margin-bottom: 20px;
}

.footer_card_text {
  font-size: 10px;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.five_years_logo {
  display: flex;
  position: absolute;
  padding: 5px;
  width: 75px;
  height: 10px;
  align-items: flex-start;
  z-index: 10;
}
.five_years_logo span {
  font-size: 38px;
}

swiper-container::part(button-prev) {
  @apply text-primary-blue/60;
}

swiper-container::part(button-next) {
  @apply text-primary-blue/60;
}

swiper-container::part(bullet-active) {
  @apply bg-primary-blue;
}
</style>
