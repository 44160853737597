<template>
  <div class="relative w-full ">
    <div
      class="cursor-pointer absolute top-4 right-4 w-8 h-8 before:content-[''] before:absolute before:w-8 before:h-px before:bg-black before:rotate-45 after:content-[''] after:absolute after:w-8 after:h-px after:bg-black after:-rotate-45 z-30"
      @click="modalStore.hideModal"
    ></div>
    <div class="bg-white rounded-md z-10 relative overflow-y-auto max-h-[768px] md:h-auto xl:max-h-[768px] 2xl:overflow-auto">
      <div class="container py-4 relative">
        <h2 class="text-center uppercase text-black font-semibold text-3xl mb-4">Подробные условия акции</h2>
        <div class="grid gap-5 text-[14px] space-y-4">
          <p>Выгода, применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. Условия настоящей программы могут сочетаться с условиями «Программы Trade-in». </p>
          <p><sup>2</sup>Выгода по программе "Трейд-ин" - это единовременная и разовая скидка по программе "Трейд-ин", предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля с пробегом. </p>
          <p><sup>3</sup>Выгода по программе «Geely Finance» предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке: для «Geely Atlas Pro» в комплектациях «Comfort» и «Luxury» в размере 200 000 рублей, в комплектациях «Flagship» и «Flagship+» в размере 220 000 рублей; «Geely Monjaro» в размере 200 000 рублей; «Geely Emgrand» в размере 200 000 рублей; «Geely Ocavango» в размере 250 000 рублей; «Geely Новый Coolray» в размере 150 000 рублей и «Geely Atlas» в размере 150 000 рублей. Условия настоящей программы не могут сочетаться с условиями «Программы поддержки корпоративных продаж», «Geely Лизинг». </p>
          <p><sup>4</sup>Выгода по программе «Лояльный Трейд-ин» - это единовременная и разовая скидка, предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely». </p>
          <p><sup>5</sup>Выгода по программе «Специальный Трейд-ин» - это единовременная и разовая скидка, предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля популярной марки (список марок уточняйте в дилерских центрах «Geely»). </p>
          <p><sup>6</sup>При приобретении конечным покупателем Автомобиля с использованием кредитных средств любого Банка, по условиям Программы, Дилер снижает розничную цену нового Автомобиля для конечного покупателя на сумму не менее указанной. Размер суммы зависит от использования программы в сочетании с программой Трейд-ин. Условия настоящей программы не сочетаются с условиями программы Специальная выгода. </p>
          <p>*Выгода в размере 350 000 рублей указана для автомобилей «Geely Emgrand», и состоит из выгоды при покупке в размере 200 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 150 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>** Выгода в размере 300 000 рублей указана для автомобилей «Новый Geely Preface»  и состоит из выгоды при покупке в кредит в размере 150 000 рублей и выгоды по программе «Трейд-ин» в размере 150 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 200 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>*** Выгода в размере 300 000 рублей указана для автомобилей «Geely Coolray» и состоит из выгоды в размере 125 000 рублей, применяемой к цене автомобиля, выгоды при покупке комплектации в кредит в размере 150 000 рублей и выгоды по программе «Специальный Трейд-ин» в размере 300 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>**** Выгода в размере 390 000 рублей указана для автомобилей «Geely Cityray» и состоит из выгоды при покупке комплектаций Luxury, Flagship и Flagship Sport в кредит в размере 200 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 190 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>***** Выгода в размере 600 000 рублей указана для автомобилей «Geely Atlas Pro» и состоит из выгоды в размере 110 000 рублей, применяемой к цене автомобиля, выгоды при покупке в кредит в размере 220 000 рублей и выгоды по программе «Трейд-ин» в размере 270 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>****** Выгода в размере 350 000 рублей указана для автомобилей «Новый Geely Atlas» и состоит из выгоды при покупке в кредит в размере 150 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 200 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>******* Выгода в размере 450 000 рублей указана для автомобилей «Geely Okavango» и состоит из выгоды при покупке в кредит в размере 250 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 200 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>******** Выгода в размере 450 000 рублей указана для автомобилей «Geely Monjaro» и состоит из выгоды при покупке в кредит в размере 200 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 250 000 рублей. Программа действует с 01.10.2024 по 31.10.2024 г.</p>
          <p>Программа действует с 01.10.2024 по 31.10.2024 г. Подробные условия уточняйте у менеджеров отдела продаж АТЦ Кунцево по телефону +7(495)933 40 33. Реклама. Не оферта.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";
import { useMessageStore } from "@/stores/messageStore";
import { storeToRefs } from 'pinia'
import { useDealerStore } from "@/stores/dealerStore";

const modalStore = useModalStore()
const messageStore = useMessageStore()
const dealerStore = useDealerStore();
const { modalMessage } = storeToRefs(modalStore)
const { getDealerById } = storeToRefs(dealerStore);
</script>

<style scoped>
article {
  @apply space-y-2
}
h3 {
  font-size: 16px;
  font-weight: 700;
}
h4 {
  font-size: 14px;
  font-weight: 500;
}
a[href] {
  /* text-decoration: underline; */
  color: #002E5D;
}
</style>